import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import {
  ChangedValuesType,
  ForecastType,
  ForecastStatusType,
  ParametersUpdateStatusType,
} from "../../types/forecasts";

export const getForecasts = createAsyncThunk(
  "forecasts/get-forecasts",
  async (params?: any) => {
    try {
      const {
        data,
      }: { data: Array<ForecastType> } = await api.forecasts.getForecasts(
        params
      );
      return data;
    } catch (err) {}
  }
);

export const getForecastById = createAsyncThunk(
  "forecasts/get-forecast-by-id",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: ForecastType } = await api.forecasts.getForecastById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createForecast = createAsyncThunk(
  "forecasts/create-forecast",
  async (values: ForecastType, thunkAPI) => {
    try {
      const { data } = await api.forecasts.createForecast(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateForecast = createAsyncThunk(
  "forecasts/update-forecast",
  async (values: { id: number; values: ForecastType }, thunkAPI) => {
    try {
      const { data } = await api.forecasts.updateForecast(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateForecastValues = createAsyncThunk(
  "forecasts/update-forecast-values",
  async (values: { data: Array<ChangedValuesType> }, thunkAPI) => {
    try {
      const { data } = await api.forecasts.updateForecastValues(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteForecast = createAsyncThunk(
  "forecasts/delete-forecast",
  async (id: number) => {
    try {
      const { data }: { data: string } = await api.forecasts.deleteConstant(id);
      return data;
    } catch (err) {}
  }
);

export const getForecastStatus = createAsyncThunk(
  "forecasts/get-forecast-status",
  async () => {
    try {
      const {
        data,
      }: {
        data: Array<ForecastStatusType>;
      } = await api.forecasts.getForecastStatus();
      return data;
    } catch (err) {}
  }
);

export const updateForecastStatus = createAsyncThunk(
  "forecasts/update-forecast-status",
  async (values: ParametersUpdateStatusType, thunkAPI) => {
    try {
      const data = await api.forecasts.updateForecastStatus(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const uploadDuetto = createAsyncThunk(
  "forecasts/upload-duetto",
  async (values: any, thunkAPI) => {
    try {
      const data = await api.forecasts.uploadDuetto(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const uploadTimeplan = createAsyncThunk(
  "forecasts/upload-timeplan",
  async (values: { forecastID: number }, thunkAPI) => {
    try {
      const data = await api.forecasts.uploadTimeplan(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const saveValueComment = createAsyncThunk(
  "forecasts/save-value-comment",
  async (values: any, thunkAPI) => {
    try {
      const { data } = await api.forecasts.saveValueComment(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getValueComments = createAsyncThunk(
  "forecasts/update-value-comment",
  async (id: number, thunkAPI) => {
    try {
      const { data } = await api.forecasts.getValueCommentsById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getForecastComments = createAsyncThunk(
  "forecasts/get-forecast-comments",
  async (id: number, thunkAPI) => {
    try {
      const { data } = await api.forecasts.getForecastComments(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
