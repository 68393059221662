export const navBar = [
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/admin/users",
    icon: "cil-people",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Countries",
    to: "/admin/countries",
    icon: "cil-globe-alt",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Hotels",
    to: "/admin/hotels",
    icon: "cil-house",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Forecasts",
    route: "/admin/forecasts",
    icon: "cil-chart-pie",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "In progress",
        to: "/admin/forecasts?status=created",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Approval requests",
        to: "/admin/forecasts?status=pending",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Approved",
        to: "/admin/forecasts?status=approved",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Archived",
        to: "/admin/forecasts?status=archived",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Notifications",
    to: "/admin/notifications",
    icon: "cil-bell",
  },
];

export const navBarInputMaker = [
  {
    _tag: "CSidebarNavItem",
    name: "Hotels",
    to: "/admin/hotels",
    icon: "cil-house",
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Forecasts",
    route: "/admin/forecasts",
    icon: "cil-chart-pie",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "In progress",
        to: "/admin/forecasts?status=created",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Approval requests",
        to: "/admin/forecasts?status=pending",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Approved",
        to: "/admin/forecasts?status=approved",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Archived",
        to: "/admin/forecasts?status=archived",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Notifications",
    to: "/admin/notifications",
    icon: "cil-bell",
  },
];
